<template>
  <div id="page-user-view" class="p-6">
    <div class="flex items-center justify-between mb-4">
      <h2>{{$t('Discounts')}}</h2>
      <span v-if="$store.state.AppActiveUser.userRole !== 'sales'" class="font-medium text-primary cursor-pointer">
        <vs-button
          @click="newDiscount('Add')"
          color="primary"
          icon-pack="feather"
          icon="icon-plus"
          size="large"
        >
        </vs-button>
      </span>
    </div>
    <shipblu-table
      :sst="true"
      multiple
      v-model="selected"
      pagination
      :max-items="maximumItems"
      :data="discounts"
      :tableLoader="tableLoader"
      :hideHeader="true"
    >
      <template slot="thead">
        <shipblu-th>{{$t('ID')}}</shipblu-th>
        <shipblu-th v-if="$route.name === 'finance-accounts-discounts'">{{$t('Merchant Name')}}</shipblu-th>
        <shipblu-th>{{$t('Code')}}</shipblu-th>
        <shipblu-th>{{$t('Value')}}</shipblu-th>
        <shipblu-th>{{$t('Description')}}</shipblu-th>
        <shipblu-th>{{$t('Statement')}}</shipblu-th>
        <shipblu-th v-if="$store.state.AppActiveUser.userRole !== 'sales'" class="flex justify-center">
          <feather-icon icon="ChevronDownIcon" svgClasses="h-5 w-5"/>
        </shipblu-th>
      </template>

      <template slot-scope="{ data }">
        <shipblu-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <shipblu-td class="sm:hidden block col-span-2 p-0 order-3">
            <!-- line -->
            <p style="background-color: #DCDCDC;height: 1px;"></p>
          </shipblu-td>

          <shipblu-td class="order-4" :data="data[indextr].id">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('ID')}}</p>
            <p class="sm:text-base text-sm">
              {{ data[indextr].id }}
            </p>
          </shipblu-td>

          <shipblu-td class="order-5" v-if="$route.name === 'finance-accounts-discounts'" :data="data[indextr].merchantName">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Merchant')}}</p>
            <p class="sm:text-base text-sm">
              {{ data[indextr].statement && data[indextr].statement.merchant ? data[indextr].statement.merchant.name : '' }}
            </p>
          </shipblu-td>

          <shipblu-td :data="data[indextr].code" class="text-left sm:order-3 order-16 sm:pointer-events-none">
            <div class="flex sm:static absolute top-3 gap-2 items-start justify-start transform-50" v-on:click.stop>
              <p class="sm:text-base text-lg flex gap-1 items-start justify-center">
                {{ data[indextr].code }}
              </p>
            </div>
          </shipblu-td>

          <shipblu-td class="order-6" :data="data[indextr].value">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Value')}}</p>
            <p class="sm:text-base text-sm">
            {{ data[indextr].value.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) }}
            </p>
          </shipblu-td>

          <shipblu-td class="order-7" :data="data[indextr].description">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Description')}}</p>
            <p class="sm:text-base text-sm">
              {{ data[indextr].description }}
            </p>
          </shipblu-td>

          <shipblu-td class="order-8" :data="data[indextr].statement">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Statement')}}</p>
            <p class="sm:text-base text-sm">
            {{ data[indextr].statement ? data[indextr].statement.number : ''}}
            </p>
          </shipblu-td>

          <shipblu-td class="order-2" style="vertical-align: middle;" v-if="$store.state.AppActiveUser.userRole !== 'sales'">
            <div class="md:text-center text-right">
              <vs-dropdown
                vs-trigger-click
                @click="selected = []"
                class="dd-actions cursor-pointer"
              >
                <span class="material-symbols-outlined text-2xl">more_horiz</span>
                <vs-dropdown-menu style="width: 190px">
                  <vs-dropdown-item @click="newDiscount('Edit', data[indextr])">
                    <span class="flex items-center">
                      <feather-icon
                        icon="EditIcon"
                        svgClasses="h-4 w-4"
                        class="mr-2"
                      />
                      <span>{{$t('Edit')}}</span>
                    </span>
                  </vs-dropdown-item>
                  <vs-dropdown-item @click="deleteDiscount(data[indextr])">
                    <span class="flex items-center">
                      <feather-icon
                        icon="TrashIcon"
                        svgClasses="h-4 w-4"
                        class="mr-2"
                      />
                      <span>{{$t('Delete')}}</span>
                    </span>
                  </vs-dropdown-item>
                </vs-dropdown-menu>
              </vs-dropdown>
            </div>
          </shipblu-td>
        </shipblu-tr>
      </template>
    </shipblu-table>
    <shipblu-pagination :maximumItems="maximumItems" :noOptions="true" :totalRows="totalRows" :currentPage="currentPage" @currentPage="currentPage = $event" />

    <discounts :type="type" :addDiscountModal="addDiscountModal" :statements="statements" :statementsDic="statementsDic"
    @statement="statement = $event" @addDiscountModal="addDiscountModal = $event" :discount="discount" @loadDiscounts="loadDiscounts"></discounts>
  </div>
</template>

<script>
import {sendRequest} from '../../../http/axios/requestHelper.js'
import Discounts from './Discounts.vue'
import ShipbluTable from '../../../layouts/components/NewShipBluTable.vue'
import ShipbluTr from '../../../layouts/components/ShipBluTr.vue'
import ShipbluTh from '../../../layouts/components/ShipBluTh.vue'
import ShipbluTd from '../../../layouts/components/ShipBluTd.vue'
import i18nData from '../../../i18n/i18nData.js'
import ShipbluPagination from '../../../layouts/components/ShipbluPagination.vue'

export default {
  data () {
    return {
      maximumItems: process.env.VUE_APP_MAXIMUM_ITEMS_TABLE,
      discounts: [],
      selected: [],
      deleteData: {},
      addDiscountModal: false,
      type: '',
      discount: {
        value: '',
        description: '',
        code: ''
      },
      merchantID: '',
      totalRows: 0,
      offset: 0,
      currentPage: (this.$route.query.page &&  Number(this.$route.query.page) > 0) ? Number(this.$route.query.page) : 1,
      statements: [],
      file_URl: '',
      file_key: '',
      merchants: [],
      statementsDic: {},
      tableLoader: false,
      statement: {}
    }
  },
  watch: {
    currentPage () {
      this.offset = (this.currentPage - 1) * this.maximumItems
      this.$router.push({
        query: {
          page: this.currentPage
        }
      }).catch(() => {})
      this.loadDiscounts()
    }
  },
  methods: {
    loadStatements () {
      sendRequest(false, false, this, `api/v1/billing/statements/?merchant=${this.$route.params.merchantID ? this.$route.params.merchantID : ''}&limit=1000`, 'get', null, true,
        (response) => {
          this.statements = response.data.results
          this.statements.forEach(element => {
            this.statementsDic[element.id] = element.number
          })
        }
      )
    },
    loadDiscounts () {
      this.tableLoader = true
      const query = `?statement__merchant=${this.$route.params.merchantID ? this.$route.params.merchantID  : ''}&offset=${this.offset}&limit=${this.maximumItems}`
      sendRequest(true, false, this, `api/v1/billing/discounts/${query}`, 'get', null, true,
        (response) => {
          this.discounts = response.data.results
          this.totalRows = response.data.count
          this.tableLoader = false
        }
      )
    },
    newDiscount (type, data) {
      this.type = type
      if (type === 'Add') {
        this.discount = {
          value: '',
          description: '',
          code: ''
        }
      } else {
        this.discount = data
      }
      this.addDiscountModal = true
    },
    deleteDiscount (data) {
      this.deleteData = data
      this.$vs.dialog({
        color: 'danger',
        title: 'Confirm',
        text: i18nData[this.$i18n.locale]['Are you sure to delete?'],
        accept: this.confirmDelete
      })
    },
    confirmDelete () {
      sendRequest(false, false, this, `api/v1/billing/discounts/${this.deleteData.id}/`, 'delete', null, true,
        () => {
          this.loadDiscounts()
        }
      )
    }
  },
  components: {
    Discounts,
    ShipbluTable,
    ShipbluTr,
    ShipbluTh,
    ShipbluTd,
    ShipbluPagination
  },
  created () {
    this.offset = (this.currentPage - 1) * this.maximumItems
    this.loadStatements()
    this.loadDiscounts()
  }
}
</script>
<style lang="scss">
@import "@/assets/scss/vuexy/apps/email.scss";
.con-pagination-table{
  display: none;
}
</style>
